import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ArticleBody from "../components/articleBody"
import ArticleHero from "../components/articleHero"


const NewsDetail = ({ location, data }) => {
  const newsItem = data.allSanityNewsItem.edges[0].node;

  return (
    <Layout scheme="dark" location={location} pageCategory="agency">
      <Seo title={newsItem.title} />

      <div className="container header-spacer">
        {newsItem.image && (
          <ArticleHero image={newsItem.image.image} altText={newsItem.image.altText} />
        )}
        <ArticleBody 
          source={newsItem.source}
          title={newsItem.title}
          subhead={newsItem.subhead}
          date={newsItem.datePublished}
          text={newsItem._rawArticleText}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    allSanityNewsItem(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          source
          subhead
          datePublished
          _rawArticleText
          image {
            altText
            image {
              ...ImageWithPreview
            }
          }
        }
      }
    }
  }
`

export default NewsDetail