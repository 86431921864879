import * as React from 'react'
import SanityImage from "gatsby-plugin-sanity-image"
import PropTypes from 'prop-types'

const ArticleHero = ({ image, altText }) => {
  return (
    <div className="article__hero">
      <SanityImage {...image} 
        className="article__hero-img"
        alt={altText} 
      >
      </SanityImage>
    </div>
  )
}

ArticleHero.propTypes = {
  image: PropTypes.object,
  altText: PropTypes.string
}

export default ArticleHero