import * as React from 'react'
import {PortableText} from '@portabletext/react'
import PropTypes from 'prop-types'

const ArticleBody = ({ title, subhead, source, text, date }) => {
  return (
    <article className='article__body'>
      <div className='article__info'>
        <h5 className='article__source'>{source}</h5>
        <h1 className='article__title'>{title}</h1>
        {subhead && (<h2 className='article__subhead'>{subhead}</h2>)}
        <h5 className='article__date'>{date}</h5>
      </div>
      <div className='article__text'>
       <PortableText value={text} />
      </div>
      
    </article>
  )
}

ArticleBody.propTypes = {
  title: PropTypes.string.isRequired,
  subhead: PropTypes.string,
  source: PropTypes.string.isRequired,
  text: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired
}

export default ArticleBody